import { Component, OnInit, Output,EventEmitter, OnChanges} from '@angular/core';
import { AuthService } from '../service/auth/auth.service';

@Component({
  selector: 'app-monthyear',
  templateUrl: './monthyear.component.html',
  styleUrls: ['./monthyear.component.scss']
})
export class MonthyearComponent implements OnInit {
  public year;
  public month;

  @Output() filterEvent = new EventEmitter<any>();

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    let monthNow = new Date().getMonth()
    let yearNow = new Date().getFullYear();
    this.month = monthNow + 1;
    this.year = yearNow;

    this.sendMonthYear()
  }

  changeMonth() { 
    this.authService.dashboardApi.next(true)
    this.sendMonthYear()

  }
  changeYear(){
  
   this.sendMonthYear()
  }

  sendMonthYear() {
   
    if( typeof(this.month) == 'string' ) {
      console.log('string' + this.month);
      let change = parseInt(this.month)
      this.month = change ;
    }
    if( typeof(this.year) == 'string' ) {
      console.log('string' + this.year);
      let change = parseInt(this.year)
      this.year = change;
    }

    let emitData = {
      month: this.month,
      year: this.year
    }
    this.filterEvent.emit(emitData);
  }

}
