<select name="month" id="" class="mr-2" style="border:none;  text-align-last:right;" [(ngModel)] = "month" (change)="changeMonth()" >
    <option value="1">January</option>
    <option value="2">Feburary</option>
    <option value="3">March</option>
    <option value="4">April</option>
    <option value="5">May</option>
    <option value="6">June</option>
    <option value="7">July</option>
    <option value="8">August</option>
    <option value="9">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
</select>
<select name="year" id="" style="border:none  ;text-align-last:right;" [(ngModel)] = "year" (change)="changeYear()">
    <option value="2020">2020</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
</select>