import { AuthService } from './../service/auth/auth.service';
import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';
@Component({
  selector: 'app-projectdetail',
  templateUrl: './projectdetail.component.html',
  styleUrls: ['./projectdetail.component.scss']
})
export class ProjectdetailComponent implements OnInit {
  public projectData = undefined;
  @ViewChild('engineerSearchInput', { static: true }) engineerSearchInput: ElementRef;
  public proID;
  public engrID;
  emailShare;
  public engineerName;
  constructor( private authService:AuthService, private route: ActivatedRoute, private router:Router) { }

  ngOnInit(): void {

    this.authService.header.next(true)
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      // do your task for before route
      return false;
    }
   this.getValue()
    fromEvent(this.engineerSearchInput.nativeElement, 'keyup').pipe(

      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      // , filter(res => res.length > 2)

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current   
      , distinctUntilChanged()
    ).subscribe( data => {
      console.log(data);

    })
  }

  getEngineer() {
    if(this.engineerName && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.engineerName)) {
      this.authService.searchPE({email: this.engineerName}).subscribe( data => {
        console.log(data);
        this.emailShare = data
       return alert("Project shared successfully.")
      })
      return
    }
    return alert("Invalid email address")
  }


  getValue() {
    const id = this.route.snapshot.paramMap.get('id')
    this.proID = id
    this.authService.getSingleProject(id).subscribe((data: any) => {
      this.projectData = data;
      console.log(data);
    })
  }

  clientDocs(data) {
    // console.log(data);
    if (data==null) {
      return [];
    }
    
     return data.filter( (res:any) => res.docType == 'client')
  }

  engineerDocs(data) {
    if (data == null) {
      return [];
    }
     return data.filter( (res:any) => res.docType == 'engineer')
  }
  shareProjectPE() {
    this.authService.shareProject({id: this.engrID}, this.proID).subscribe(data => {
      console.log(data);
    })
  }

  getuniqueId(i) {
     return `id${i}`
  }
  setuniqueId(i) {
    return `#id${i}`
  }

}
