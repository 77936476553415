import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-totalrevenue',
  templateUrl: './totalrevenue.component.html',
  styleUrls: ['./totalrevenue.component.scss']
})
export class TotalrevenueComponent implements OnInit {

  @Output() totalrevenueYear = new EventEmitter()
  @Input() totalrevenue;
  public startDate = "2020-02-01";
  public endDate;


  constructor() { }

  ngOnInit(): void {
    this.endDate = new Date().toISOString().substring(0, 10) ;
    console.log(this.endDate);
    this.getdate()
    
  }
  getStartDate() {
    this.getdate()
    console.log('zdfzdf');
    
  }
  getEndDate() {
    this.getdate()
  }

  getdate(){
    console.log({ startDate: this.startDate, endDate: this.endDate});
    
    this.totalrevenueYear.emit({ startDate: this.startDate, endDate: this.endDate})

  }


}
