import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-totalpe',
  templateUrl: './totalpe.component.html',
  styleUrls: ['./totalpe.component.scss']
})
export class TotalpeComponent implements OnInit {
  @Output() totalPeYear = new EventEmitter()
  @Input() totalpe;

  constructor() { }

  ngOnInit(): void {
  }
  getDate(ev){
    console.log(ev);
    this.totalPeYear.emit(ev)
  }

}
