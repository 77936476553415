import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private cookie: CookieService){}
  ngOnInit(){
    // this.socket.emit('tryReconnect', {owner: 'admin'})
  }
}
