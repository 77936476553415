<div class="main-container" style="min-height: 92vh"> 
        <div class="container">
          <div class="d-flex justify-content-between my-5">
            <form >
              <div class="input-group " style=" width: 160%;">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <img src="../../assets/icons/search.svg" alt="" style="    width: 18px;">
                  </span>
                </div>
                <input type="text" class="form-control" placeholder="Search "  name ="email" #search>
              </div>
            </form>
          
            <div class="d-flex">
              <div class="filter mr-3" data-toggle="modal" data-target="#filterModal">
                <span>Filter</span><img src="../../assets/icons/chevron-down.svg" alt="">
            </div>
            <button class="btn btn-primary export" (click)="export()">Export</button>
            </div>
        </div>
        <button type="button" *ngIf="resetBtn == true" class="btn btn-primary mb-2" (click) = "filterReset()">Reset Filter</button>
        <p class="my-4" *ngIf="resetBtn == true  "  style="font-weight: bold;">Filter applied:
          <span class="appliedFilter" *ngIf="startDate && endDate !=undefined">Projects from {{startDate | date}} to {{endDate | date}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="startDate = undefined;endDate = undefined;loadingbar = false; applyFilter()"></span>
          <!-- <span class="appliedFilter" *ngIf="email != undefined">{{email}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="email = undefined;getFilter()"></span> -->
      </p>
        </div>
        <div class="container" style="background-color: white;padding: 40px;min-height: 24vh;" *ngIf="loadingbar == true; else loading" >


          <ng-container *ngIf = "PEData?.length != 0; else emptyStates">
            <table class="table  ">
                <thead>
                  <tr> 
                    <th scope="col">S.NO</th>
                    <th scope="col">NAME</th>
                    <th scope="col">PROJECTS</th>
                    <th scope="col">REVENUE GENERATED</th>
                    <th scope="col">COMMISSION GENERATED</th>
                    <th scope="col">OPTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor = "let customer of PEData | paginate: { itemsPerPage: 10, currentPage: p, totalItems: totalDocs }; let i = index" >
                    <th scope="row" class="align-middle">{{ i + 1}}</th>
                    <td class="align-middle" [routerLink] = "['/customers', customer._id]">{{customer.username | titlecase}} <span *ngIf="customer.stripeCustomerID == undefined" style=" background: #ffc107;
                      padding: 4px 10px;
                      border-radius: 19px;
                      font-size: 12px;
                      font-weight: 600;
                      color: white;">Incomplete</span></td>
                    <td class="align-middle" [routerLink] = "['/customers', customer._id]">{{customer.totalProjects}}</td>
                    <td class="align-middle" [routerLink] = "['/customers', customer._id]">${{customer.totalRevenue }}</td>
                    <td class="align-middle" [routerLink] = "['/customers', customer._id]">${{customer.totalCommission}}</td>
                    <td>
                      <button type="button" *ngIf="customer.block == false" class="btn btn-danger btn-sm" (click)="blockOrUnblock(customer._id, customer.block)"> <img src="../../assets/icons/block.svg" alt="" style="width: 18px; position: relative;bottom: 1px;"> Block</button>
                      <button type="button" *ngIf="customer.block == true" class="btn btn-primary btn-sm" (click)="blockOrUnblock(customer._id, customer.block)"> Unblock</button>

                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex align-items-coner justify-content-center mt-5 " *ngIf="totalDocs > 10">
                <pagination-controls  (pageChange)=" pageChanged($event)"></pagination-controls>
               </div>
          </ng-container>
              <ng-template #emptyStates>
                <app-emptystates [status]="'No Customers'" ></app-emptystates>
              </ng-template>
            </div>


</div>
<ng-template #loading>
  <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
    <app-loading></app-loading>
</div>
</ng-template>



<!-- filter Modal -->

<!-- Modal -->
<div class="modal fade" id="filterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="filterModalLabel">Customer Filter</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <div class="row">
            <div class="col-4">
              <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a class="nav-link active" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Signup date</a>
              </div>
            </div>
            <div class="col-8">
              <div class="tab-content" id="v-pills-tabContent">
                
                <div class="tab-pane fade active show" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                  <div class="form-group">
                    <label >Start Date</label>
                    <input type="date" placeholder="Start Date" name="startDate" [(ngModel)] = "startDate" [max]="future_dates"style="margin-left: 5px;">
                  </div>
                  <div class="form-group">
                    <label >End Date</label>
                    <input type="date" placeholder="Start Date" name="endDate" [(ngModel)] = "endDate" [max]="future_dates" style="margin-left: 5px;">
                  </div>
                  <div *ngIf="startDate > endDate" style="font-size: 14px;color: red;">
                    * End date must be greater than start date
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
        </div>
        <div class="modal-footer">
          <button type="reset" class="btn btn-secondary">Reset</button>
          <button type="button" class="btn btn-primary" [disabled] = "isDisabled()" (click) = " applyFilter() "data-dismiss="modal">Apply Filter</button>
        </div>
      </form>
    </div>
  </div>
</div>