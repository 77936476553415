<div class="main-container mb-5 pb-5">
  <div class="container" >
    <div class="row top-box">
      <div class="col-xl-7">
          <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link active" (click) ="projectStatus = undefined; p = 1;pagenumber=1;loadingBar = false; getAllProjects();" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">All Projects</a>
                  <a class="nav-item nav-link" (click) ="projectStatus = 'open'; p = 1;pagenumber=1;loadingBar = false; getAllProjects();" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Open</a>
                  <a class="nav-item nav-link" (click) ="projectStatus = 'ongoing'; p = 1;pagenumber=1;loadingBar = false; getAllProjects();" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Ongoing</a>
                  <a class="nav-item nav-link" (click) ="projectStatus = 'completed';p = 1;pagenumber=1;loadingBar = false; getAllProjects();" id="nav-completed-tab" data-toggle="tab" href="#nav-completed" role="tab" aria-controls="nav-completed" aria-selected="false">Completed</a>

              </div>
          </nav>
      </div>
      <div class="col-xl-5">
          <div class="row">
              <div class="offset-xl-4 col-xl-4 ">
                  <div class="filter" data-toggle="modal" data-target="#filterModal">
                      <span>Filter</span><img src="../../assets/icons/chevron-down.svg" alt="">
                  </div>
              </div>
              <div class="col-xl-4 ">
                <button class="btn btn-primary export" (click)="export()">Export</button>

            </div>
             
          </div>
      </div>
  </div>

  <form class="mt-4" >
    <div class="input-group " style=" width: 30%;">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <img src="../../assets/icons/search.svg" alt="" style="    width: 18px;">
        </span>
      </div>
      <input type="text" class="form-control" placeholder="Search " (keyup)="emailFilter()" name ="email" [(ngModel)] = "email">
    </div>
  </form>


  <button type="button" *ngIf="resetBtn == true" class="btn btn-primary mt-4" (click) = "Reset();heroForm.resetForm()">Reset Filter</button>
  <div *ngIf="resetBtn == true">
   <p class="mt-5" style="font-weight: bold;">Filter applied:
      <span class="appliedFilter" *ngIf="licence !=undefined">{{licence}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="licence = undefined;applyFilter()"></span> 
      <span class="appliedFilter" *ngIf="location !=undefined">{{location}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="location = undefined;applyFilter()"></span> 
      <span class="appliedFilter" *ngIf="startDate && endDate !=undefined">Projects from {{startDate | date}} to {{endDate | date}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="startDate = undefined;endDate = undefined;applyFilter()"></span>
      <span class="appliedFilter" *ngIf="startCost && endCost !=undefined">Cost from ${{startCost}} to ${{endCost}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="startCost = undefined;endCost = undefined;applyFilter()"></span> 
  </p>

  </div>
  </div>
    <div class="container"  *ngIf="loadingBar == true; else loading">
        
        <div class="contant-box" style="min-height: 59vh;">
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <ng-container *ngIf="(allProjects | async)?.docs.length == 0; else allEmptystates" >
                    <div style="background: white;padding: 31px 31px 21px;">
                      <app-emptystates  [status]="'No Projects'"></app-emptystates>
                    </div>
                  </ng-container>
                    <ng-template #allEmptystates>
                      <ng-container *ngFor="let projects of (allProjects | async)?.docs | paginate: { itemsPerPage: 5, currentPage: p, totalItems: totalDocs}">
                        <app-card [cardData]="projects" ></app-card>

                      </ng-container>
                    </ng-template>  
                     <div class="d-flex justify-content-center" *ngIf = "totalDocs > 5">
                      <pagination-controls (pageChange)=" pageChanged($event)"></pagination-controls>

                     </div>
                </div> 
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <ng-container *ngIf="(allProjects | async)?.docs.length == 0; else allEmptystates" >
                    <div style="background: white;padding: 31px 31px 21px;">
                      <app-emptystates  [status]="'No Projects'"></app-emptystates>
                    </div>
                  </ng-container>
                    <ng-template #allEmptystates>
                      <ng-container *ngFor="let projects of (allProjects | async)?.docs | paginate: { itemsPerPage: 5, currentPage: p, totalItems: totalDocs}">
                        <app-card [cardData]="projects" ></app-card>

                      </ng-container>
                    </ng-template>  
                     <div class="d-flex justify-content-center" *ngIf = "totalDocs > 5">
                      <pagination-controls (pageChange)=" pageChanged($event)"></pagination-controls>

                     </div>
                </div>
                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                  <ng-container *ngIf="(allProjects | async)?.docs.length == 0; else allEmptystates" >
                    <div style="background: white;padding: 31px 31px 21px;">
                      <app-emptystates  [status]="'No Projects'"></app-emptystates>
                    </div>
                  </ng-container>
                    <ng-template #allEmptystates>
                      <ng-container *ngFor="let projects of (allProjects | async)?.docs | paginate: { itemsPerPage: 5, currentPage: p, totalItems: totalDocs}">
                        <app-card [cardData]="projects" ></app-card>

                      </ng-container>
                    </ng-template>  
                     <div class="d-flex justify-content-center" *ngIf = "totalDocs > 5">
                      <pagination-controls (pageChange)=" pageChanged($event)"></pagination-controls>

                     </div>
                </div>
                <div class="tab-pane fade" id="nav-completed" role="tabpanel" aria-labelledby="nav-completed-tab">
                  <ng-container *ngIf="(allProjects | async)?.docs.length == 0; else allEmptystates" >
                    <div style="background: white;padding: 31px 31px 21px;">
                      <app-emptystates  [status]="'No Projects'"></app-emptystates>
                    </div>
                  </ng-container>
                    <ng-template #allEmptystates>
                      <ng-container *ngFor="let projects of (allProjects | async)?.docs | paginate: { itemsPerPage: 5, currentPage: p, totalItems: totalDocs}">
                        <app-card [cardData]="projects" ></app-card>

                      </ng-container>
                    </ng-template>  
                     <div class="d-flex justify-content-center" *ngIf = "totalDocs > 5">
                      <pagination-controls (pageChange)=" pageChanged($event)"></pagination-controls>

                     </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf = "pagination.length > 1">
          <ul>
            <li *ngFor="let item of collection | paginate: { itemsPerPage: 10, currentPage: p }"> ... </li>
          </ul>
        </div> -->
    </div>
</div>

<ng-template #loading>
  <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
    <app-loading></app-loading>
</div>
</ng-template>


<!-- filter Modal -->

<!-- Modal -->
<div class="modal fade" id="filterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="filterModalLabel">Project Filter</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form #heroForm="ngForm">
            <div class="modal-body">
                <div class="row">
                    <div class="col-4">
                      <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">License</a>
                        <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Location</a>
                        <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Date</a>
                        <a class="nav-link" id="v-pills-cost-tab" data-toggle="pill" href="#v-pills-cost" role="tab" aria-controls="v-pills-cost" aria-selected="false">Cost</a>

                      </div>
                    </div>
                    <div class="col-8">
                      <div class="tab-content mt-0" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                          <div class="form-group ">
                            <select class="form-control "  name="licence" [(ngModel)]="licence" title="Some placeholder text..."  style="border: 1px solid #ced4da; ">
                                <option data-hidden="true" disabled selected="selected">Select</option>
          
                                <option value="Agricultural and Biological Engineering">Agricultural and Biological Engineering</option>
                                <option  value="Architectural Engineering">Architectural Engineering</option>
                                <option  value="Chemical">Chemical</option>
                                <option  value="Civil">Civil</option>
                                <option  value="Control Systems">Control Systems</option>
                                <option  value="Electrical and Computer">Electrical and Computer</option>
                                <option  value="Environmental">Environmental</option>
                                <option  value="Fire Protection">Fire Protection</option>
                                <option  value="Industrial and Systems">Industrial and Systems</option>
                                <option  value="Mechanical">Mechanical</option>
                                <option  value="Metallurgical and Materials">Metallurgical and Materials</option>
                                <option  value="Mining and Mineral Processing">Mining and Mineral Processing</option>
                                <option  value="Naval Architecture and Marine">Naval Architecture and Marine</option>
                                <option  value="Nuclear">Nuclear</option>
                                <option  value="Petroleum">Petroleum</option>
                                <option  value="Structural">Structural</option>
                        </select>
                        </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                          <div class="form-group">
                            <!-- <input type="text" class="form-control" name="location" [(ngModel)]="location"  placeholder="type here"> -->
                            <select class="form-control "  name="location" [(ngModel)]="location" title="Some placeholder text..."  style="border: 1px solid #ced4da; ">
                              <option data-hidden="true" disabled selected="selected">Select</option>

                              <option  [value]="state" *ngFor="let state of states">{{state}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                          <div class="form-group">
                            <label >Start Date</label>
                            <input type="date" placeholder="Start Date" name="startDate" [(ngModel)]="startDate" [max]="future_dates " style="margin-left: 5px;">
                          </div>
                          <div class="form-group">
                            <label >End Date</label>
                            <input type="date" placeholder="Start Date" name="endDate" [(ngModel)]="endDate"  [max]="future_dates " style="margin-left: 5px;">
                          </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-cost" role="tabpanel" aria-labelledby="v-pills-cost-tab">
                            <div class="form-group">
                              <label >Start Cost</label>
                              <input type="number" name="startCost" [(ngModel)]="startCost" style="margin-left: 5px;">
                            </div>
                            <div class="form-group">
                              <label >End Cost</label>
                              <input type="number"  name="endCost" [(ngModel)]="endCost" style="margin-left: 5px;">
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="reset" class="btn btn-secondary">Reset</button>
                <button type="button" class="btn btn-primary" (click) = "applyFilter()" data-dismiss="modal">Apply Filter</button>
              </div>
        </form>

      </div>
    </div>
  </div>