import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../service/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customerdetail',
  templateUrl: './customerdetail.component.html',
  styleUrls: ['./customerdetail.component.scss']
})
export class CustomerdetailComponent implements OnInit {

  public clientData;
  public totalRevenue = 0;
  public loadingbar = false;
  public seeMore = false;
  

  constructor( private authService: AuthService, private route: ActivatedRoute, private router: Router) { }
  
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      // do your task for before route
      return false;
    }
    this.authService.header.next(true);
    this.getdata()
  }

  getdata() {
    const id = this.route.snapshot.paramMap.get('id')
    this.authService.getClientDetail(id).subscribe((data: any) => {
      this.loadingbar = true;
      console.log(data);
      this.clientData = data;
      let ref = data.project.filter(res =>  res.status !== 'open');
      console.log(ref);
      
      this.totalRevenue = ref.reduce((total, arr) => arr.budget +arr.commission + arr.revisionCommission+ arr.revenueRevision + total , 0); 
      console.log(this.totalRevenue); 
   
    }, err =>  this.loadingbar = true)
  }

  blockOrUnblock(id,data){
    console.log(data + id);
    
    var sendData;
   if(data == true){
     sendData = false;
   } else {
     sendData = true;
   }
   this.authService.getClientBlock(id,{block:sendData}).subscribe(data => {
    this.loadingbar = false
    this.getdata()
   })
  }
  projectArr(data) {
    if(this.seeMore == false) {
      return data.slice(0,5)
    } else {
      return this.clientData.project
    }

  }
  
}
