import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-emptystates',
  templateUrl: './emptystates.component.html',
  styleUrls: ['./emptystates.component.scss']
})
export class EmptystatesComponent implements OnInit {
  @Input() status;

  constructor() { }

  ngOnInit(): void {
    console.log(status);
    
  }

}
