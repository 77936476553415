import { CookieService } from 'ngx-cookie-service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public API = environment.apiURL;

  public header = new Subject();
  public dashboardApi = new BehaviorSubject(false);
  public dahsboardRES = new Subject();
  public notificationData = new Subject();

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  login(data){
    return this.http.post( this.API + '/login',data)
  }
  logout(){
    return this.http.post( this.API + '/logout',{})
  }
  forgetPassword(data){
    return this.http.post(this.API + '/forgot/password', data)
  }
  resetPassword(id,data){
    return this.http.patch(this.API + '/reset/password/' + id, data)
  }
  getClient(data, page) {
    let params = new HttpParams();
    params = params.append("limit","10")
    params = params.append("page", page)
    return this.http.post( this.API + '/client/list', data,{params:params})
  }
  getClientDetail(id) {
    return this.http.get( this.API + '/client/details/' + id)
  }
  getPE(data, page) {
    let params = new HttpParams();
    params = params.append("limit","10")
    params = params.append("page", page)
    return this.http.post( this.API + '/pe/list', data, {params:params})
  }
  getPEdetails(id){
    return this.http.get( this.API + '/pe/details/' + id)
  }
  getProject(data,page, status) {
    let params = new HttpParams()
   if(status != undefined) {
    params = params.append('status', status)
   }
    params = params.append('skip', page)
    params = params.append('limit', '5')
    return this.http.post( this.API + '/project/list',data, {params:params} )
  }
  // projectFiter(data, page) {
  //   let params = new HttpParams().set('skip', page);
  //   return this.http.post( this.API + '/project/list', data, {params})
  // }
  getSingleProject(id) {
    return this.http.get( this.API + '/project/info/' + id)
  }
  getDashboard(data) {
    return this.http.post( this.API + '/dashboard', data)
  }
  getPeBlock(id, data) {
    return this.http.post(this.API + '/pe/control/'+ id, data)
  }
  getClientBlock(id, data) {
    return this.http.post(this.API + '/client/control/'+ id, data)
  }
  isLoggedIn(){
    return !!this.cookieService.check('token')
  }
  sendAlert(id){
    return this.http.post(this.API + '/pe/alert/email' , {id:id})
  }
  searchPE(data) {
    return this.http.post( this.API + '/search/engineer', data)
  }

  shareProject(data,id) {
    return this.http.post( this.API + '/project/' + id, data)
  }

  clearNotification() {
    return this.http.post(this.API + '/clear', {})
  }

  exportData(data) {
    return this.http.post(this.API + '/data/export', {type:data},{ observe: 'response',  responseType: "blob" })
  }

  searchApi(data,text) {
    return this.http.post(this.API + '/data/search', {type:data,search:text},{ observe: 'response',  responseType: "blob" })
  }
}
