import { Injectable,Injector } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {  Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private cookie: CookieService, private router: Router){}

    intercept(req:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>> {
        console.log(req.url);
        const copiedReq = req.clone({
            setHeaders: { Authorization: `Bearer ${this.cookie.get('token')}` }
        })

        return next.handle(copiedReq).pipe(
            // retry on failure
            // retry(2),
            // handle errors
            catchError((error: HttpErrorResponse) =>{
                if(error.status < 200){
                    if( error.error ) {
                       alert(error.error);
                    } else {
                     alert(`Informational response Error`);
                    }
                }
                if(error.status < 400){
                 if( error.error ) {
                     console.log(error);                   
                    alert(error.error.error);
                 } else {
                  alert(`Client errors `);
                 }
               }
               if(error.status < 600){
                   if(error.status == 404) {
                      this.router.navigate(['/login'])
                      return
                   }
                 if( error.error ) {
                     console.log(error);     
                    alert(error.error.error);
                 } else {
                  alert(` Server errors`);
                 }
               }
             return throwError(error)
            })
        )
    }
}