<div class="card" >
    <div class="card-body"  >
        <h5 style="font-weight: 700;" >{{card?.projectName}}
            <span class="status open" *ngIf="card?.status == 'open'" [routerLink] = "['/projects', card?._id]" > Open</span>
            <span class="status ongoing" *ngIf="card?.status == 'ongoing'" [routerLink] = "['/projects', card?._id]" >Ongoing</span>
            <span class="status completed" *ngIf="card?.status == 'completed'" [routerLink] = "['/projects', card?._id]" >Completed</span>
            <!-- <span style="float: right;" *ngIf="card?.status == 'open'" data-toggle="modal" data-target="#shareModal" (click) = "proID = card?._id" >
               <img src="../../assets/icons/share.svg">
             </span> -->
        </h5> 
        <p class="mb-2" style="font-weight: bold;color: #0066ff;" [routerLink] = "['/projects', card?._id]">Project Location:
             <span style="color: black;  font-weight: normal;">{{card?.state}}</span>
            </p>
        <div class="d-flex" *ngIf="card?.clientID?.username !== undefined" [routerLink] = "['/projects', card?._id]">
            <!-- <div>
                <img src="./../../assets/icons/user.svg" alt="" style="width: 50px;">
            </div> -->
            <div [routerLink] = "['/projects', card?._id]">
                <p class="mb-2" style="font-weight: bold;color: #0066ff;">Client Name:
                    <span style="color: black;  font-weight: normal;">{{card?.clientID?.username | titlecase}}</span>
                   </p>
                <p class="mb-0" style="font-weight: 500;"></p>
            </div>
        </div>
                                       
        <div  class="d-flex mt-2 mb-2" [routerLink] = "['/projects', card?._id]">
            <div class="d-flex  align-items-end" >
                <div>
                    <img src="./../../assets/icons/license.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                </div>
                <div class="ml-2">
                    <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">License Type</p>
                    <p class="m-0" style="font-weight: 600;">{{card?.licenseType | titlecase}}</p>
                </div>
            </div>
            <div class="d-flex align-items-end ml-4" *ngIf="card?.status == 'open'">
                <div>
                    <img src="./../../assets/icons/bids.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                </div>
                <div class="ml-2">
                    <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Bids</p>
                    <p class="m-0" style="font-weight: 600;">{{card?.totalBids}}</p>
                </div>
            </div>
            <div class="d-flex align-items-end ml-4"  *ngIf="card?.status == 'completed' || card?.status == 'ongoing'">
                <div>
                    <img src="./../../assets/icons/dollor.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                </div>
                <div class="ml-2">
                    <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Project Cost</p>
                    <p class="m-0" style="font-weight: 600;">{{card?.budget + card?.commission + card?.revenueRevision + card?.revisionCommission}}</p>
                </div>
            </div>
            <div class="d-flex align-items-end ml-4" >
                <div>
                    <img src="./../../assets/icons/calender.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                </div>
                <div class="ml-2">
                    <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Posted on</p>
                    <p class="m-0" style="font-weight: 600;">{{card?.createdAt |  date:'short' : '-0800'}}</p>
                </div>
            </div>
            <div class="d-flex align-items-end ml-4" *ngIf="card?.status == 'completed'" >
                <div>
                    <img src="./../../assets/icons/calender.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                </div>
                <div class="ml-2">
                    <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Ended on</p>
                    <p class="m-0" style="font-weight: 600;">{{card?.completedDate | date:'short' : '-0800'}}</p>
                </div>
            </div>
            <div class="d-flex align-items-end ml-4" *ngIf="card?.engineerID?.username" >
                <div>
                    <img src="./../../assets/icons/engineer.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                </div>
                <div class="ml-2">
                    <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Engineer</p>
                    <p class="m-0" style="font-weight: 600;">{{card?.engineerID?.username | titlecase}}</p>
                </div>
            </div>
            <div class="d-flex align-items-end ml-4" *ngIf="ratingCheck(card?.engineerID?.engineer_rating)" >
                <div class="ml-2">
                    <p class="mb-0"  style="font-size: 14px;font-weight: 800;color: #B8BAC7;">AVG PE Rating</p>
                <img src="../../assets/icons/star.svg" alt=""> <span style=" margin: 5px; font-size: 14px;font-weight: 600; ">{{card.engineerID?.engineer_rating}}</span>
                </div>
            </div>
            
        </div>
        
    </div>
</div>

<div class="modal fade" id="shareModal" tabindex="-1" role="dialog" >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >Share Project</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <form action="">
                <div class="form-group">
                    <label for="recipient-name" class="col-form-label">Search Email</label>
                    <input type="text" class="form-control" id="recipient-name" #engineerSearchInput>
                  </div>
                  <div class="container d-flex align-items-center" *ngIf="emailShare?.engnr != undefined">
                      <h3 class="m-0 mr-2" style="font-size: 20px; font-weight: 600;">{{emailShare?.engnr.username}}</h3>
                      <button type="reset" class="btn btn-primary" (click)= "engrID = emailShare?.engnr._id; shareProjectPE() " data-dismiss="modal">Send</button>
                  </div>
                  <div class="container d-flex align-items-center" *ngIf="emailShare?.error != undefined">
                      <h3 class="m-0 mr-2" style="font-size: 20px; font-weight: 600;">No such engineer found!</h3>
                  </div>
            </form>
            
        </div>
      </div>
    </div>
  </div>