import { DashboardGuard } from './dashboard.guard';
import { LoginGuard } from './login.guard';
import { CustomerdetailComponent } from './customerdetail/customerdetail.component';
import { EngineerdetailComponent } from './engineerdetail/engineerdetail.component';
import { CustomerComponent } from './customer/customer.component';
import { EngineerComponent } from './engineer/engineer.component';
import { ProjectdetailComponent } from './projectdetail/projectdetail.component';
import { ProjectsComponent } from './projects/projects.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  {
    path: '', redirectTo: '/login', pathMatch: 'full'
  },
  {
    
    path: 'login', component: LoginComponent, canActivate:[LoginGuard]
  },
  {
    path: 'dashboard', component: DashboardComponent,canActivate:[DashboardGuard]
  },
  {
    path: 'resetpassword/:id', component:ForgetpasswordComponent,canActivate:[LoginGuard]
  },
  {
    path: 'projects', component:ProjectsComponent,canActivate:[DashboardGuard]
  },
  {
    path: 'projects/:id', component:ProjectdetailComponent,canActivate:[DashboardGuard]
  },
  {
    path: 'engineers', component:EngineerComponent,canActivate:[DashboardGuard]
  },
  {
    path: 'engineers/:id', component:EngineerdetailComponent,canActivate:[DashboardGuard]
  },
  {
    path: 'customers', component:CustomerComponent,canActivate:[DashboardGuard]
  },
  {
    path: 'customers/:id', component:CustomerdetailComponent,canActivate:[DashboardGuard]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
