import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

 @Input('documents')documents;

  constructor() { }

  ngOnInit(): void {
    console.log(this.documents);
  }

}
