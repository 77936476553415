import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-my-line-chart',
  templateUrl: './my-line-chart.component.html',
  styleUrls: ['./my-line-chart.component.scss']
})
export class MyLineChartComponent implements OnInit, OnChanges {

  @Input() graphName;
  @Input() money = [];
 

  public dataArr = [0,0,0,0,0,0,0,0,0,0,0,0]

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September', 'October', 'November','December'];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: { //you're missing this
      yAxes: [{
         scaleLabel: {
            display: true,
            labelString: 'Price in $'
         }
      }]
   },
   legend: {
    labels: {
        // This more specific font property overrides the global property
        fontStyle: 'bold',
        fontSize: 16
    }
}
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(0,102,255)',
      backgroundColor: 'rgba(0,102,255,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    
    this.dataArr = [0,0,0,0,0,0,0,0,0,0,0,0]
    
    // console.log(this.money);
    this.money.forEach(( el, i) => {
       this.dataArr[el._id - 1] = el.profit
    })

    // console.log(this.dataArr);
    this.lineChartData = [
      { data: this.dataArr, label: this.graphName}
    ]
    
    
  }

}
