import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';
import { AuthService } from '../service/auth/auth.service';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input('cardData') card;
  @ViewChild('engineerSearchInput', { static: true }) engineerSearchInput: ElementRef;
  public emailShare ;
  public proID;
  public engrID;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    console.log(this.card);
    fromEvent(this.engineerSearchInput.nativeElement, 'keyup').pipe(

      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      // , filter(res => res.length > 2)

      // Time in milliseconds between key events
      , debounceTime(1000)

      // If previous query is diffent from current   
      , distinctUntilChanged()
    ).subscribe( data => {
      console.log(data);
      this.authService.searchPE({email: data}).subscribe( data => {
        console.log(data);
        this.emailShare = data
      })
    })
  }
  ratingCheck(value){
    
    if(value == null) {
      return false
    }
    return true
    
  }

  shareProjectPE() {
    this.authService.shareProject({id: this.engrID}, this.proID).subscribe(data => {
      console.log(data);
    })
  }

}
