import { Component, OnInit,Output,EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-totalclient',
  templateUrl: './totalclient.component.html',
  styleUrls: ['./totalclient.component.scss']
})
export class TotalclientComponent implements OnInit {
  @Output() totalclientYear = new EventEmitter();
  @Input() totalclient ;

  constructor() { }

  ngOnInit(): void {
    
  }
  getDate(ev){
    console.log(ev);
    this.totalclientYear.emit(ev)
  }

}
