import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../service/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engineerdetail',
  templateUrl: './engineerdetail.component.html',
  styleUrls: ['./engineerdetail.component.scss']
})
export class EngineerdetailComponent implements OnInit {

  public pageNumber = 0;
  public engineerData;
  public loadingbar = false;
  public seeMore = false;
  public totalRevenue = 0;

  public gloabalActiveClass = 0;
  public tabLocationArr = [];

  public professionModifies = []



  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      // do your task for before route
      return false;
    }
    this.getDetails()
  }

  getDetails(){
    const id = this.route.snapshot.paramMap.get('id')

    this.authService.header.next(true);
    this.authService.getPEdetails(id).subscribe((data:any) => {
      this.loadingbar = true;
      console.log(data);
      this.engineerData = data;
      this.LicensePlay()
      let ref = data.projects[0].projects.filter(res =>  res.status !== 'open');
      console.log(ref);
      
      this.totalRevenue = ref.reduce((total, arr) => arr.budget + arr.revenueRevision + total , 0); 
      console.log(this.totalRevenue);
      
    }, err => this.loadingbar = true  )
  }

  getRevenue(value) {
    if(value == null || value == undefined){
      return 0
    }
   return value
  }
  showAlert(data: boolean[]) {
    return data.some(res => res == true)
 }

 blockOrUnblock(id,data) {
  this.loadingbar = false;
   var sendData;
   if(data == true){
     sendData = false;
   } else {
     sendData = true;
   }
   this.authService.getPeBlock(id,{block:sendData}).subscribe(data => this.getDetails())
  }

  projectArr(data) {
    if(this.seeMore == false) {
      return data.slice(0,5)
    } else {
      return this.engineerData.projects[0].projects
    }

  }

  sendNotification(){
    this.loadingbar = false;
    const id = this.route.snapshot.paramMap.get('id')
    this.authService.sendAlert(id).subscribe(data => {
      console.log(data)
      this.getDetails()
    })
  }

  changeClass(i, license) {
    this.gloabalActiveClass = i;
    console.log(license);
    this.tabLocationArr = license.location;
  }

  LicensePlay() {
    console.log(this.engineerData.engineer[0].engineerID.profession)
    let simpleArr = []
    this.engineerData.engineer[0].engineerID.profession.map((res, i) => {

      let duplicate = simpleArr.some((ele) => {
        return ele.license == res.licence_type
      })

     if(duplicate) {
      let index = simpleArr.findIndex((val) => val.license == res.licence_type)
       console.log(simpleArr[index].location.push(res.state))

     } else {

      simpleArr.push({license : res.licence_type, location : [res.state]})

     }
    })
    console.log("licensed ARr", simpleArr)
    this.professionModifies = simpleArr
    this.tabLocationArr = simpleArr[0].location
  }
  
  

}
