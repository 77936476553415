<header>
    <nav *ngIf="navigationLinks == true" class="navbar navbar-expand-lg ">
        <a class="navbar-brand mr-3" routerLink="/dashboard/myproject" style="color: black;">
            <img src="../../../assets/icons/logo.png" alt="" style="    width: 158px;">
            <!-- <b>Illumine I</b> | Admin -->
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <img src="../../assets/icons/menu.svg" alt="">
        </button>

        <div class="collapse navbar-collapse ml-lg-5" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto links">
                <li class="nav-item mr-5 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/dashboard">Dashboard <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item mr-5 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/projects/">Projects <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item mr-5 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/engineers">Engineers</a>
                </li>
                <li class="nav-item mr-5 borderitem">
                    <a class="nav-link navbartext" routerLinkActive="actived" routerLink="/customers">Customers</a>
                </li>
            </ul>
            <ul class="navbar-nav my-2 my-lg-0">
                <li class="nav-item mr-3 dropdown " >
                    <a class="nav-link notification px-4 dropdown-toggle" id="notifidown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../assets/icons/bellempty.svg" alt="" > <span *ngIf = "showNotifyIcon() " class="badge badge-light" >&nbsp;</span> </a>
                    <div class="dropdown-menu notify" aria-labelledby="notifidown" id="style-3">
                        
                        <div class="notify-container">
							<p class="notifications" (click)="$event.stopPropagation()">Notifications <span style="color:red; font-size: 14px;float:right;position: relative; top: 5px;" (click) = "clearNotify()">Clear</span></p>
                            <ng-container *ngIf = "projectNotifyData.length != 0; else NoProjectNotify">
                                <div *ngFor="let notifydata of projectNotifyData ; let i = index">
                                    <div  class="item-box "[ngStyle]="{'background-color': notifydata.status == 'unread' ? 'transparent' : ' rgb(247 246 246)'}">
                                        <div class="items textvalue" (click)="goto(notifydata?._id,notifydata?.projectID,notifydata?.engineerID,notifydata?.clientID)" >
                                            <p style="font-size:16px" *ngIf="notifydata?.projectID == null && notifydata?.clientID != null"><b>{{notifydata?.clientID.username | titlecase}}</b></p>
                                            <p style="font-size:16px" *ngIf="notifydata?.projectID != null && notifydata?.clientID != null || notifydata?.projectID != null && notifydata?.clientID != null && notifydata?.engineerID != null"><b>{{notifydata?.projectID.projectName | titlecase}}</b></p>
                                            <p style="font-size:16px" *ngIf="notifydata?.projectID == null && notifydata?.engineerID != null"><b>{{notifydata?.engineerID.username | titlecase}}</b></p>
                                            <p style="font-size:14px"> {{notifydata?.content}}</p>
                                        </div>
                                    </div>
                                 
                                </div>
                            </ng-container>
                            <ng-template #NoProjectNotify>
                                 <app-emptystates [status]="'No notification'"></app-emptystates>
                            </ng-template>
						</div>
                    </div>
                </li>
                <li class="nav-item dropdown profilepic py-3 py-lg-0">

                    <a class="nav-link dropdown-toggle" id="dropdown07" >
           
                        <span class="downarrow" (click)="logout()">Logout</span> <span>
                            
                        </span></a>
                    
                </li>
            </ul>
        </div>
    </nav>
    <nav *ngIf="navigationLinks == false" class="navbar navbar-expand-md navbar-dark fixed-top fakenav" style="box-shadow: none!important;">
        <a class="navbar-brand mr-3 brand" routerLink="/myprojetcs" style="color: black;">
        
            <img src="../../../assets/icons/logo.png" alt="" style="    width: 158px;">

        </a>
    </nav>
</header>