<div class="main-container" >
    <div class="container" *ngIf="loadingbar == true; else loading">
        <div class="heading-text">
            <p>Engineers<span class="image"><img src="../../assets/icons/chevron-right.svg" alt=""></span> <span style="color:  #0066FF;">Engineer Details</span></p>
        </div>
        <div class="content-box mb-4">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-5">
                            <div class="d-flex  align-items-center">
                                <div class="mr-4">
                                    <!-- <img src="../../assets/icons/user.svg" alt="" style="width: 120px;"> -->
                                </div>
                                 <div >
                                    <h4>{{engineerData?.engineer[0].engineerID.username | titlecase}}</h4>
                                    <p>{{engineerData?.engineer[0].engineerID.email}}</p>
                                    <p>{{engineerData?.engineer[0].engineerID.phone == 'null' ? null : engineerData?.engineer[0].engineerID.phone}}</p>
                                    <h5>{{engineerData?.engineer[0].engineerID.engineer_rating}}<span><img src="../../assets/icons/star.svg" alt="" style=" width: 15px; margin-left: 6px; position: relative;bottom: 2px;"></span></h5>
                                </div>
                            </div>
                        </div>
                        <div class="offset-4 col-3">
                            <div class=" d-flex justify-content-end">
                                <button *ngIf="showAlert(engineerData?.alert[0].value)" (click)="sendNotification();" [disabled] = "engineerData?.engineer[0].engineerID.alertEmail == true"  type="button" style="margin: 0 13px;color: white;" class="btn btn-warning mb-2"> <img src="../../assets/icons/alert.svg" alt="" style="width: 18px; position: relative;bottom: 1px;"> Alert</button>
                                <button *ngIf="engineerData?.engineer[0].engineerID.block == false" (click)="blockOrUnblock(engineerData?.engineer[0].engineerID._id ,engineerData?.engineer[0].engineerID.block )" type="button" class="btn btn-danger mb-2"> <img src="../../assets/icons/block.svg" alt="" style="width: 18px; position: relative;bottom: 1px;"> Block</button>
                                <button *ngIf="engineerData?.engineer[0].engineerID.block == true" (click)="blockOrUnblock(engineerData?.engineer[0].engineerID._id ,engineerData?.engineer[0].engineerID.block )" type="button" class="btn btn-primary mb-2">Unblock</button>    
    
                            </div>
                            <h5 class="text-right">Total Earned</h5>
                            <p class="text-right" style="font-weight: 700;font-size: 20px;">${{ totalRevenue  }}</p>
                        </div>
                    </div>
                    <!-- <div class="d-flex flex-wrap mt-4">
                        <div *ngFor="let profession of engineerData?.engineer[0].engineerID.profession" class="mr-3 mb-3" style="background: #E5EFFC;padding: 15px;">
                            <p class="mb-2" style="font-weight: 600;"><img src="../../assets/icons/license.svg" alt="" style="width: 20px;margin-right: 4px;"> {{profession.licence_type | titlecase}} </p>
                            <p class="mb-0" style="font-weight: 600;"><img src="../../assets/icons/map.svg" alt="" style="margin-left:6px;margin-right: 4px;">{{profession.state | titlecase}}</p>
                        </div>
                    </div> -->
                    <div>
                        <div class="d-flex flex-wrap">
                          <ng-container *ngFor="let profession of professionModifies; let i = index">
                            <div
                              class="tabsss"
                              [ngClass]="{ activeTab: gloabalActiveClass === i ? true : false }"
                              (click)="changeClass(i, profession)"
                            >
                              {{ profession.license }}
                            </div>
                          </ng-container>
                        </div>
                        <div class="tab-content my-4 d-flex flex-wrap">
                          <ng-container *ngFor="let state of tabLocationArr; let i = index">
                            <div style="padding: 8px 20px; background: #f2f2f2; border-radius: 30px; margin: 0 3px; margin-bottom: 8px;">
                              
                                {{ state }}
                            </div>
                          </ng-container>
                        </div>
              
                        <hr />
                        <p class="mt-2" style="font-size: 14px; color: #a9a9a9">The above states are associated to the licenses</p>
                      </div>
                    <!-- end of license -->
                </div>
            </div>
        </div>
        <h5 class="mb-4" style="font-weight:700;">Worked on Projects</h5>
           <ng-container class="card" *ngIf="engineerData?.projects.length != 0; else projectEmpty">
             <app-card [cardData]="project" *ngFor="let project of projectArr(engineerData?.projects[0].projects)"></app-card>
            <button class="btn btn-primary" *ngIf="engineerData?.projects.length > 5 && seeMore == false;" (click) = "seeMore = true"> See More</button>
           </ng-container>
           <ng-template #projectEmpty>
             <div class="card">
               <div class="card-body pt-4">
                <app-emptystates [status]="'No Projects'" ></app-emptystates>
               </div>
             </div>
          </ng-template>

          <h5 class="mb-4 mt-3" style="font-weight:700;">Feedbacks</h5>
          <div class="card">
              <div class="card-body">
                <table class="table table-borderless" *ngIf="engineerData?.engineer[0].feedbacks.length != 0; else yoyoy">
                    <thead>
                      <tr>
                        <th scope="col">Customers</th>
                        <th scope="col">Review</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor = "let review of engineerData.engineer[0].feedbacks">
                        <td style="width: 43%">
                            
                                <h5 class="mb-0" style="font-weight: 700;">{{review?.clientID.username}}</h5>
                                <!-- <img src="../../assets/icons/map.svg" alt=""> {{review?.clientID.username}} <span style=" margin: 5px; font-size: 14px;font-weight: 600; ">Chennai</span> -->
                                <p class="mt-2">{{ review.date | date}}</p>
                            </td>
                        <td>
                            <h5>{{review?.rating}}<span><img src="../../assets/icons/star.svg" alt="" style=" width: 15px; margin-left: 6px; position: relative;bottom: 2px;"></span></h5>
                            {{review?.feedback}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ng-template #yoyoy>
                    <app-emptystates [status]="'No Feedback'" ></app-emptystates>
                  </ng-template>
              </div>
          </div>
    </div>
    <ng-template #loading>
        <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
          <app-loading></app-loading>
      </div>
    </ng-template>
</div>
