import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './../service/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { SocketoneService,SockettwoService } from '../socketconnection.service';
declare var $ : any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public navigationLinks = false;
  public projectNotifyData = []

  constructor(private router: Router, private authService: AuthService, private socket:SocketoneService,private socket2: SockettwoService, private cookie: CookieService) { }

  ngOnInit(): void {
    this.authService.header.subscribe((data:any) => {
      console.log(data);
      if(data == true){
        this.navigationLinks = true;
      } else{
        this.navigationLinks = false;
      }
    })
    this.authService.notificationData.subscribe((data: any) => {
      this.projectNotifyData = data
      this.projectNotifyData.reverse()
    })
   
    this.socket.on('singleNotify', (data:any) => {
      console.log(data);
      this.projectNotifyData.unshift(data)
      
    })
    this.socket2.on('singleNotify', (data:any) => {
      console.log(data);
      this.projectNotifyData.unshift(data)
      
    })
    if (this.cookie.check('token')) {
      console.log(`jwt ${this.cookie.get('token')}`);
      this.socket.on('isReconnected', data => {
        console.error(data);
        this.socket.emit('tryReconnect', {'token': this.cookie.get('token'),'owner': 'admin' })   
        this.socket.on('onReconnection', data => {
          console.log(data);
          this.projectNotifyData = data
          this.projectNotifyData.reverse()
          
        })
      })
      this.socket2.on('isReconnected', data => {
        console.error(data);
        this.socket2.emit('tryReconnect', {'token': this.cookie.get('token'),'owner': 'admin' })   
        this.socket2.on('onReconnection', data => {
          console.log(data);
          this.projectNotifyData = data
          this.projectNotifyData.reverse()
          
        })
      })
    }
    else {
      console.log('no token');
    }
  }
  test(e) {
    console.log(e.target);
    e.preventDefault()
    e.stopPropagation();
    $(e.target).tab('show')
  }

  logout(){
    this.authService.logout().subscribe( data => {
      console.log(data)
      this.router.navigate(['/login'])
      this.cookie.deleteAll('/')
    })
  }

  goto(Id, projectID, engineerID,clientID){
    // console.log(this.projectNotifyData);
  
    this.projectNotifyData.filter(projID => {
      if(projID._id === Id){
        return projID.status = "read"
      }
    })
    console.log(this.projectNotifyData)
    this.socket.emit('onNotificationRead',{id: Id})
    if(projectID == null && engineerID != null) {
      this.router.navigate([`/engineers/${engineerID._id}`])
      return
    }
    if((projectID != null && clientID != null) || (projectID != null && clientID != null && engineerID != null)) {
      this.router.navigate([`/projects/${projectID._id}`])
      return
    }
    if((projectID == null && clientID != null)){
      this.router.navigate([ `/customers/${clientID._id}`])
      return
    }

  }

  showNotifyIcon(){
    if( this.projectNotifyData.some( res => res.status == "unread")) {
      return true
    }
      return false
  }

  clearNotify() {
    this.authService.clearNotification().subscribe( data => {
      this.projectNotifyData = []
    })
  }


}
