<div class="main-container" >

        <div class="container">
          <div class="d-flex justify-content-between my-5">
            <form >
              <div class="input-group " style=" width: 160%;">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <img src="../../assets/icons/search.svg" alt="" style="    width: 18px;">
                  </span>
                </div>
                <input type="text" class="form-control" placeholder="Search "  name ="email" #search>
              </div>
            </form>
          
            <div class="d-flex">
              <div class="filter mr-3" data-toggle="modal" data-target="#filterModal">
                <span>Filter</span><img src="../../assets/icons/chevron-down.svg" alt="">
            </div>
            <button class="btn btn-primary export" (click)="export()">Export</button>
            </div>

        </div>
        <button type="button" *ngIf="resetBtn == true" class="btn btn-primary mb-4" (click) = "filterReset()">Reset Filter</button>
        <p class="my-2" *ngIf="resetBtn == true " style="font-weight: bold;">Filter applied:
          <span class="appliedFilter" *ngIf="license != undefined">{{license}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="license = undefined;applyFilter()"></span>
          <span class="appliedFilter" *ngIf="location != undefined">{{location}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="location = undefined;applyFilter()"></span>

          <span class="appliedFilter" *ngIf="startDate && endDate !=undefined">Projects from {{startDate | date}} to {{endDate | date}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="startDate = undefined;endDate = undefined;applyFilter()"></span>
          <!-- <span class="appliedFilter" *ngIf="email != undefined">{{email}} <img class="clear" src="../../assets/icons/x-circle (1).svg" alt="" (click)="email = undefined;applyFilter()"></span> -->
      </p>
        </div>

        <div class="container" style="background-color: white;padding: 40px;min-height: 24vh;" *ngIf="loadingbar == true; else loading">

            <ng-container *ngIf = " PEData.engnr.length != 0; else emptyStates">
              <table class="table  ">
                <thead>
                  <tr>
                    <th scope="col">S.NO</th>
                    <th scope="col">NAME</th>
                    <th scope="col">LICENSE</th>
                    <th scope="col">PROJECTS</th>
                    <th scope="col">TOTAL EARNED</th>
                    <th scope="col">RATING</th>                
                    <th scope="col">OPTIONS</th>
                  </tr>
                </thead>
                <tbody *ngIf="PEData.totalDocs != 0">
                  <tr *ngFor="let pe of PEData.engnr | paginate: { itemsPerPage: 10, currentPage: p, totalItems: totalDocs }; let i = index;" style="cursor: pointer;" >
                    <th scope="row" class="align-middle" [routerLink] = "['/engineers', pe._id]">{{p == 1 ? i+1 : i + ((p - 1 )+ 10) }}</th>
                    <td class="align-middle" [routerLink] = "['/engineers', pe._id]">{{pe.username | titlecase}} <span *ngIf="pe.account_id == undefined" style="    background: #ffc107;
                      padding: 4px 10px;
                      border-radius: 19px;
                      font-size: 12px;
                      font-weight: 600;
                      color: white;">Incomplete</span></td>
                    <td class="align-middle" [routerLink] = "['/engineers', pe._id]">{{pe.profession}}</td>
                    <td class="align-middle" [routerLink] = "['/engineers', pe._id]">{{pe.noofprojects}}</td>
                    <td class="align-middle" [routerLink] = "['/engineers', pe._id]">${{ handleBudget(pe?.revenue[0]?.revenue)}}</td>
                    <td class="align-middle" [routerLink] = "['/engineers', pe._id]">{{pe.engineer_rating}}</td>
                    <td>
                      <button type="button" *ngIf="pe.block == false" class="btn btn-danger btn-sm" (click)="blockOrUnblock(pe._id, pe.block)" > <img src="../../assets/icons/block.svg" alt="" style="width: 18px; position: relative;bottom: 1px;"> Block</button>
                      <button type="button" *ngIf="pe.block == true" class="btn btn-primary btn-sm" (click)="blockOrUnblock(pe._id, pe.block)" > Unblock</button>

                      <button type="button" *ngIf="showAlert(pe.alert[0].value)" [disabled] = "pe.alertEmail == true" (click)="sendAlertMsg(pe._id)" class="btn btn-warning btn-sm" style="color: white;margin-left: 8px;"> <img src="../../assets/icons/alert.svg" alt="" style="width: 18px; position: relative;bottom: 1px;;"> Alert</button>
                    </td>
                  </tr>
                 

                </tbody>
               
              </table>
            <div class="d-flex align-items-coner justify-content-center mt-5 " *ngIf="totalDocs > 10">
              <pagination-controls  (pageChange)=" pageChanged($event)"></pagination-controls>
             </div>
            </ng-container>

            <ng-template #emptyStates>
              <app-emptystates [status]="'No Engineers'" ></app-emptystates>
            </ng-template>
        </div>
    
</div> 

<ng-template #loading>
  <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
    <app-loading></app-loading>
</div>
</ng-template>


<!-- filter Modal -->

<!-- Modal -->
<div class="modal fade" id="filterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="filterModalLabel">Engineer Filter</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> 
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">License</a>
              <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Location</a>
              <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Signup date</a>
            </div>
          </div>
          <div class="col-8">
            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div class="form-group ">
                  <select class="form-control " id="exampleFormControlSelect1 " title="Some placeholder text..." [(ngModel)] ="license"  style="border: 1px solid #ced4da; ">
                      <option data-hidden="true" disabled selected="selected">Select</option>

                      <option value="Agricultural and Biological Engineering">Agricultural and Biological Engineering</option>
                      <option  value="Architectural Engineering">Architectural Engineering</option>
                      <option  value="Chemical">Chemical</option>
                      <option  value="Civil">Civil</option>
                      <option  value="Control Systems">Control Systems</option>
                      <option  value="Electrical and Computer">Electrical and Computer</option>
                      <option  value="Environmental">Environmental</option>
                      <option  value="Fire Protection">Fire Protection</option>
                      <option  value="Industrial and Systems">Industrial and Systems</option>
                      <option  value="Mechanical">Mechanical</option>
                      <option  value="Metallurgical and Materials">Metallurgical and Materials</option>
                      <option  value="Mining and Mineral Processing">Mining and Mineral Processing</option>
                      <option  value="Naval Architecture and Marine">Naval Architecture and Marine</option>
                      <option  value="Nuclear">Nuclear</option>
                      <option  value="Petroleum">Petroleum</option>
                      <option  value="Structural">Structural</option>
              </select>
              </div>
              </div>
              <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div class="form-group">
                  <select class="form-control "  name="location" [(ngModel)]="location" title="Some placeholder text..."  style="border: 1px solid #ced4da; ">
                    <option  [value]="state" *ngFor="let state of states">{{state}}</option>
                  </select>
                </div>
              </div>
              <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div class="form-group">
                  <label >Start Date</label>
                  <input type="date" placeholder="Start Date" [(ngModel)] = "startDate" [max]="future_dates" style="margin-left: 5px;">
                </div>
                <div class="form-group">
                  <label >End Date</label>
                  <input type="date" placeholder="Start Date" [(ngModel)] = "endDate" [max]="future_dates" style="margin-left: 5px;">
                </div>
                <div *ngIf="startDate > endDate" style="font-size: 14px;color: red;">
                  * End date must be greater than start date
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="reset" class="btn btn-secondary">Reset</button>
        <button type="button" class="btn btn-primary" [disabled] = "isDisabled()" (click)="applyFilter()" data-dismiss="modal">Apply Filter</button>
      </div>
    </div>
  </div>
</div>
