import { AuthService } from './../service/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter, shareReplay } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  public resetBtn = false;
  public allProjects: Observable<any>;
  public openProjects: Observable<any>;
  public ongoingProjects: Observable<any>;
  public completedProjects: Observable<any>;

  public licence;
  public location;
  public startDate;
  public endDate;
  public startCost;
  public endCost;
  public email = '';

  public loadingBar = false;
  public pagination = [];
  public sendData = {}
  public pagenumber = 1;
  public states = []
  public future_dates = new Date().toISOString().substring(0, 10);

  public projectStatus = undefined;

  p: number = 1;
  totalDocs = 1;

  constructor(private authService: AuthService, private _FileSaverService: FileSaverService) { }

  ngOnInit(): void {
    this.authService.header.next(true);
    this.applyFilter();
    this.states = ["Alaska",
      "Alabama",
      "Arkansas",
      "American Samoa",
      "Arizona",
      "California",
      "Colorado",
      "Connecticut",
      "District of Columbia",
      "Delaware",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Iowa",
      "Idaho",
      "Illinois",
      "Indiana",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Massachusetts",
      "Maryland",
      "Maine",
      "Michigan",
      "Minnesota",
      "Missouri",
      "Mississippi",
      "Montana",
      "North Carolina",
      " North Dakota",
      "Nebraska",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "Nevada",
      "New York",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Virginia",
      "Virgin Islands",
      "Vermont",
      "Washington",
      "Wisconsin",
      "West Virginia",
      "Wyoming"]
  }

  getAllProjects() {

    this.allProjects = this.authService.getProject(this.sendData, this.p, this.projectStatus).pipe(
      shareReplay()
    );

    this.allProjects.subscribe((res) => {
      console.log(res);
      this.totalDocs = res?.totaldocs
      console.log(this.totalDocs)
      this.loadingBar = true
    }, err => {
      this.loadingBar = true

    })

  }

  applyFilter() {

    if (this.startCost > this.endCost) {
      return alert('End Cost should be more or equal to start cost')
    }
    this.loadingBar = false;
    console.log(this.email.length);

    if (this.licence == undefined && this.location == undefined && this.startCost == undefined && this.endCost == undefined && this.startDate == undefined && this.endDate == undefined) {
      if (this.email.length != 0) {
        this.sendData = { search: this.email }
        console.log('if');

      } else {
        this.sendData = {};
        console.log('else');

      }

      this.resetBtn = false
      this.getAllProjects()
      return
    }
    if (this.email.length != 0) {
      this.sendData = { search: this.email }
      console.log('if');

    } else {
      this.sendData = {};
      console.log('else');

    }

    this.sendData = {
      ...this.sendData,
      state: this.location,
      licenseType: this.licence,
      datePosted: {
        startDate: this.startDate,
        endDate: this.endDate
      },
      projectCost: {
        startCost: this.startCost,
        endCost: this.endCost
      }

    }
    console.log(this.sendData);
    this.p = 1
    this.allProjects = this.authService.getProject(this.sendData, this.pagenumber = 1, this.projectStatus).pipe(
      shareReplay()
    );

    this.allProjects.subscribe((res) => {

      this.totalDocs = res?.totaldocs
      console.log(this.totalDocs)

      console.log(res);
      this.loadingBar = true
      this.resetBtn = true
    }, err => {
      this.loadingBar = true
      this.resetBtn = false
    })

  }

  emailFilter() {
    console.log(this.email);

    if (this.email.length != 0) {
      console.log('successs');
      this.sendData = {
        ...this.sendData, search: this.email
      }
    } else {
      delete this.sendData['search']
      console.log(this.sendData)
    }
    this.applyFilter()
  }



  Reset() {
    this.loadingBar = false;
    this.sendData = {}
    if(this.email) {
      this.sendData = {
        search: this.email
      }
    }
    this.resetBtn = false
    this.getAllProjects()

  }

  pageChanged(event) {
    this.p = event;
    this.pagenumber = event
    if (this.resetBtn == true) {
      this.applyFilter();
    } else {
      this.getAllProjects()

    }
  }

  export() {
    this.authService.exportData("project").subscribe((data) => {
      const contentDisposition = data.headers.get('Content-Disposition');
      console.log(data.body);
      console.log("sdf");
      var blob = new Blob([data.body], { type: 'text/csv' });
      // var url= window.URL.createObjectURL(blob);
      // window.open(url);
      console.log(blob);
      this._FileSaverService.save(blob, 'projects.xls');


    }, (err) => console.log(err))
  }



}
