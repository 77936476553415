import { Socket } from 'ngx-socket-io';
import { AuthService } from './../service/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SocketoneService,SockettwoService } from '../socketconnection.service';
declare var $ : any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public submit = true;
  public loading = false;
 
  public error = false
  model: any = {};

  resetEmail;

  constructor( public router: Router, private authService: AuthService, private cookieService:CookieService, private socket: SocketoneService, private socket2: SockettwoService ) { }

  ngOnInit(): void {
    console.log(Date.now());
    this.authService.header.next(false); 
  }

  login() {
    console.log(this.model);
    this.submit = false;
    this. loading = true;
    let sendData = {
      email: this.model.email,
      password: this.model.password
    }
    this.authService.login(sendData).subscribe( (data:any) => {
      this.loading = false;
      this.submit = true; 
      this.cookieService.set( 'token', data.token,1,'/' );
      this.router.navigate(['/dashboard']);
      console.log(data);
      this.socket.emit('login',{ owner: 'admin'})
      this.socket.on('onloginNotify', data => {
        console.log('yoyoy');
        console.error(data);
        this.authService.notificationData.next(data)
      })
      this.socket2.emit('login',{ owner: 'admin'})
      
    },
    err => {
      console.log(err);
      this.loading = false;
      this.submit = true; 
    })
  }

  forgetpass(){

    $('#forgetpassword').modal('hide');
    $('#loadingmodal').modal('show');

    console.log(this.model.emailforget);
    this.authService.forgetPassword({'email': this.model.emailforget}).subscribe(() => {
      this.resetEmail = this.model.emailforget
      $('#loadingmodal').modal('hide');
      $('#success').modal('show');

    })
 
  }

}

