<div class="d-flex justify-content-between mb-4">
    <div>
        <label for="">Start date</label>
        <input type="date" name="" placeholder="start Date" id="" style="border: none;width: 150px;" [(ngModel)] = "startDate" (change)="getStartDate()">
    </div>
    <div>
        <label for="">End date</label>
        <input type="date" name="" placeholder="startDate" id="" style="border: none; width: 150px;" [(ngModel)] = "endDate" (change)="getEndDate()">
    </div>
</div>
<p class="" style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Total Revenue</p>
<h3 style="font-weight: bold; font-size: 30px;">${{totalrevenue}}</h3>