import { AuthService } from './../service/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { ActivatedRoute, Router } from '@angular/router';

declare var $ : any;
@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {

  public submit = true;
  public loading = false;
  public done = false;
  public error = false
  model: any = {};
  urlID;

  constructor(private actRoute: ActivatedRoute, public router: Router, private cookieService: CookieService, private authService: AuthService) { }

  ngOnInit(): void {
    this.urlID = this.actRoute.snapshot.paramMap.get('id');
  
  }

  resetpass(){
   console.log(this.model);
   
   this.submit = false;
   this.loading = true;

   this.authService.resetPassword(this.urlID,{'password' : this.model.password}).subscribe((data) => {

      console.log(data);
      console.log('password changed');
      $('#success').modal('show');   
      this.submit = true;
      this.loading = false;
      
   }, err => {
    this.submit = true;
    this.loading = false;
   })
   
  }

}

