import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-my-bar-chart',
  templateUrl: './my-bar-chart.component.html',
  styleUrls: ['./my-bar-chart.component.scss']
})
export class MyBarChartComponent implements OnInit,OnChanges {

  @Input() graphName;
  @Input() project:any[];
  @Input() sideLable;

   newDataArr = [];
   newLabelArr = [];

  public barChartOptions: ChartOptions = {}
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: any [] =[
    {
        backgroundColor:'#007bff',
        //borderColor: "rgba(10,150,132,1)",
       // borderWidth: 5
    }
]


  public barChartData: ChartDataSets[] = [
   
  ];

  constructor() { }

  ngOnInit() {
   
    this.barChartOptions = {
      responsive: true,
      scales: { //you're missing this
        yAxes: [{
           scaleLabel: {
              display: true,
              labelString: this.sideLable
           },
           ticks: {
            beginAtZero: true
        }
        }]
     },
     legend: {
      labels: {
          // This more specific font property overrides the global property
          fontStyle: 'bold',
          fontSize: 16
      }
  }
     
    };
  
  }
  ngOnChanges() {
    
    this.newLabelArr= []
    this.newDataArr = []
    this.project.forEach(element => {

      this.newDataArr.push(element.noofprojects || element.revenue)
      this.newLabelArr.push(element._id)
    });

    this.barChartData = [
      {data: this.newDataArr, label: this.graphName}
    ]
    this.barChartLabels = this.newLabelArr
  }

}
