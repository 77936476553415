<div class="main-container">
    <div class="container">
        <div class="filter" data-toggle="modal" data-target="#exampleModalCenter">
            
            <a href="https://dashboard.stripe.com/login">Payment Gateway</a>
        </div>
        <div class="d-flex justify-content-between">
            
            <div class="card" style="width: 32%;">
                <div class="card-body">
                    <div class="d-flex justify-content-between my-4">
                        <div>
                            <span style="background:#ddffd7;padding: 2px 14px;font-weight: 700;border-radius: 3px;">{{clientpercent}}</span>
                        </div>
                        <div>
                            <select name="month" id="" class="mr-2" style="border:none;  text-align-last:right;" [(ngModel)] = "month_client" (change)="getFilter();customerLoad = false;" >
                                <option value="1">January</option>
                                <option value="2">Feburary</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <select name="year" id="" style="border:none  ;text-align-last:right;" [(ngModel)] = "year_client" (change)="getFilter();customerLoad = false;">
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>                        </div>
                    </div>
                    <div  class="d-flex justify-content-between ">
                        
                       <div>
                        <p style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Total Customers <span style="color: black;">{{totalClient}}</span></p>
                        <p style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Incomplete Customers <span style="color: black;">{{responseData?.incompleteClients}}</span></p>
                        <p style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Unregistered Customers <span style="color: black;">{{responseData?.unregisteredClients}}</span></p>

                       </div>

                        <div *ngIf = "customerLoad == false"  class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                     </div>
                    <!-- <h3 style="font-weight: bold; font-size: 30px;"></h3> -->
                    
                   
                </div>
            </div>
            <div class="card" style="width: 32%;">
                <div class="card-body">
                    <div class="d-flex justify-content-between my-4">
                        <div>
                            <span style="background:#ddffd7;padding: 2px 14px;font-weight: 700;border-radius: 3px;">{{pepercent}}</span>
                        </div>
                        <div>
                            <select name="month" id="" class="mr-2" style="border:none;  text-align-last:right;" [(ngModel)] = "month_pe" (change)="getFilter();engineerLoad = false;" >
                                <option value="1">January</option>
                                <option value="2">Feburary</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <select name="year" id="" style="border:none  ;text-align-last:right;" [(ngModel)] = "year_pe" (change)="getFilter();engineerLoad = false;">
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>                        </div>
                    </div>
                     <div class="d-flex justify-content-between ">
                        
                        <div>
                            <p style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Total PEs <span style="color: black;">{{totalPE}}</span></p>
                            <p style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Incomplete PEs <span style="color: black;">{{responseData?.incompleteEngineers}}</span></p>
                            <p style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Unregistered PEs <span style="color: black;">{{responseData?.unregisteredEngineers}}</span></p>

                           </div>
                        <div *ngIf = "engineerLoad == false" class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                     </div>
                    
                   
                </div>
            </div>
            <div class="card" style="width: 32%;">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-4">
                        <div>
                            <label for="">Start date</label>
                            <input type="date" name="" placeholder="start Date" id="" style="border: none;width: 150px;" [(ngModel)] = "dateRevenue.startDate" [max]="future_dates" (change)="getFilter();">
                        </div>
                        <div>
                            <label for="">End date</label>
                            <input type="date" name="" placeholder="startDate" id="" style="border: none; width: 150px;" [(ngModel)] = "dateRevenue.endDate" [max]="future_dates" (change)="getFilter();revenueLoad = false">
                        </div>
                    </div>
                    <div *ngIf="dateRevenue.startDate > dateRevenue.endDate" style="font-size: 14px;color:red ">
                        * End date must be greater than start date
                      </div>
                    <div class="d-flex justify-content-between ">
                        
                        <p style="font-size: 22px; font-weight: 700; color: #B8BAC7;">Total Revenue</p>
                        <div *ngIf = "revenueLoad == false" class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                     </div>
                    <h3 style="font-weight: bold; font-size: 30px;">${{totalRevenue}}</h3>
                </div>
            </div>

        </div>
        <div class="row my-5">
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-end" >
                            <div *ngIf = "revenueMonthLoad == false" class="spinner-border text-primary mr-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <select name="year" id="" style="border:none  ;text-align-last:right;" [(ngModel)] = "year_rpm" (change)="getFilter();revenueMonthLoad = false" >
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                        <app-my-line-chart [graphName] = "'Revenue generated in Months'" [money]="revenuepermonth" ></app-my-line-chart>
                    </div>
                </div>
                
            </div>
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <div style="display: flex;justify-content: flex-end;">
                            <div *ngIf = "commisionMonthLoad == false" class="spinner-border text-primary mr-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <select name="year" id="" style="border:none  ;text-align-last:right;" [(ngModel)] = "year_com" (change)="getFilter();commisionMonthLoad = false" >
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                        <app-my-line-chart [graphName] = "'Premium generated in Months'" [money] = "commissionpermonth"></app-my-line-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div style="display: flex;justify-content: flex-end;">
                            <div *ngIf = "projectLicenseLoad == false" class="spinner-border text-primary " role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <select name="month" id="" class="mr-2" style="border:none;  text-align-last:right;" [(ngModel)] = "month_pl" (change)="getFilter();projectLicenseLoad = false" >
                                <option value="1">January</option>
                                <option value="2">Feburary</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <select name="year" id="" style="border:none  ;text-align-last:right;" [(ngModel)] = "year_pl" (change)="getFilter();projectLicenseLoad = false" >
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                        <app-my-bar-chart [graphName] = "'Projects per License'" [sideLable]="'No.of projects'" [project]="projectperlicence" ></app-my-bar-chart>
                    </div>
                </div>
                
            </div>
            
        </div>
        <div class="row my-5">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div style="display: flex;justify-content: flex-end;">
                            <div *ngIf = "revenueLicenseLoad == false"class="spinner-border text-primary " role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <select name="month" id="" class="mr-2" style="border:none;  text-align-last:right;" [(ngModel)] = "month_lr" (change)="getFilter();revenueLicenseLoad = false"  >
                                <option value="1">January</option>
                                <option value="2">Feburary</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                            <select name="year" id="" style="border:none  ;text-align-last:right;" [(ngModel)] = "year_lr"  (change)="getFilter();revenueLicenseLoad = false" >
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                        <app-my-bar-chart  [graphName] = "'Revenue per License'" [sideLable]="'Price in $'" [project]="revenueperlicence"></app-my-bar-chart>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>