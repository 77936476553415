<main class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <img src="../../../assets/icons/logo.png" alt="">
          <div class="d-flex align-items-center  my-3 ">
            <img src="../../../assets/icons/instagram.png" alt="" style="width: 23px;margin-right: 10px; margin-left: 11px;">
            <img src="../../../assets/icons/pinterest.png" alt="" style="width: 23px; margin-right: 10px;">
            <img src="../../../assets/icons/twitter.png" alt="" style="width: 23px;">
          </div>
  
        </div>
        <div class="offset-lg-2 col-lg-2">
          <p class="link"><a routerLink="/dashboard/createproject">Post a Project</a></p>
          <p class="link m-lg-0"><a routerLink="/dashboard/createproject">Find Work</a></p>
        </div>
        <div class="col-lg-2">
          <p class="link"><a routerLink="/dashboard/createproject">Support</a></p>
          <p class="link  m-lg-0"><a routerLink="/dashboard/createproject">Blog</a></p>
        </div>
        <div class="col-lg-3">
          <p class="link"><a routerLink="/dashboard/createproject">Privacy Policy</a></p>
          <p class="link m-lg-0"><a routerLink="/dashboard/createproject">Terms and Conditions</a></p>
        </div>
      </div>
    </div>
  </main>
  